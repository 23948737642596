// @flow
import { type DefaultTheme } from "../themes";

const paymentInfoForm: (DefaultTheme) => * = (theme) => ({
  root: {
    maxWidth: "800px",
    [theme.breakpoints.up("md")]: {
      "& > form > div:nth-child(1n)": {
        width: `calc(50% - ${theme.spacing(2)})`,
        paddingBottom: 0,
      },
      "& > form > div:nth-child(2n-1)": {
        marginRight: theme.spacing(2),
      },
      "& > form > div:nth-child(2n)": {
        marginLeft: theme.spacing(2),
      },
      "& > form > div:has([data-testid=\"paymentNote\"])": {
        width: "100%",
        marginRight: 0,
      },
    },

    "& > form > button": {
      marginTop: theme.spacing(1),
      maxWidth: "210px",
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      "& > form > button": {
        maxWidth: "100%",
      },
    },
  },
});

export default paymentInfoForm;
