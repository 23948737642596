// @flow
import React, { type Node } from "react";
import type { CommonFormProps } from "@fas/cpa-cabinet-ui/lib/PaymentInfoForm";

function Paxum({
  components: {
    TextFieldForm,
    DictionarySelectFieldForm,
    Button,
    FormContainer,
  },
  onSave,
  disabled,
  validate,
  id,
}: CommonFormProps): Node {
  return (
    <FormContainer
      id={id}
      onSave={onSave}
      validate={validate}
    >
      <TextFieldForm disabled={disabled} name="paxumMail" label="Paxum Email" placeholder="What is your Paxum email?" />
      <TextFieldForm disabled={disabled} name="beName" label="Beneficiary Name" placeholder="What is your beneficiary name?" />
      <TextFieldForm disabled={disabled} name="beAddress" label="Beneficiary Adress" placeholder="What is your beneficiary adress?" />
      <DictionarySelectFieldForm disabled={disabled} name="beCountry" label="Beneficiary Country" hookProps="countryId" placeholder="What is your beneficiary country?" />
      <TextFieldForm disabled={disabled} name="vatNumber" label="Vat Number" placeholder="What is your VAT number?" />

      <Button
        disabled={disabled}
        data-testid="save"
        fullWidth
        variant="gradient"
        type="submit"
      >
        Update
      </Button>
    </FormContainer>
  );
}

export default Paxum;
