// @flow
import type { Node } from "react";
import { useSelector } from "react-redux";
import { getTableTotalAmount, getTableTotalData } from "@fas/cpa-state-manager/services/selectors";
import type { StoreWithTable } from "@fas/cpa-state-manager/services/selectors";
import Body from "@fas/cpa-cabinet-ui/lib/Table/Body";
import React from "react";
import { useReportTable } from "../../hooks";
import { MAIN_REPORT_TABLE } from "../../constants";
import { mainReportColumnsMap } from "./mainReportColumnsMap";

function TotalMain({ classes }: *): Node {
  const data: * = useSelector((state: *): * => getTableTotalData(state, MAIN_REPORT_TABLE));
  const count: number = useSelector((state: StoreWithTable): number => getTableTotalAmount(state, MAIN_REPORT_TABLE));
  const { columns, isLoading }: * = useReportTable({
    columnsMap: {
      ...mainReportColumnsMap,
      date: {
        field: "date",
        label: "",
        render: (): * => "Total",
      },
      country: {
        field: "country",
        label: "",
        render: (): * => "Total",
      },
      subId: {
        field: "subId",
        label: "",
        render: (): * => "Total",
      },
    },
    tableKey: "MAIN_REPORT_TABLE",
  });

  if (isLoading || !count) return null;
  return (
    <Body
      columns={columns}
      data={[data]}
      classes={classes}
    />
  );
}

export default TotalMain;
