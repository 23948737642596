// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent, type Element, useState, useEffect,
} from "react";
import {
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import { useTheme, withStyles } from "@mui/styles";
import { DateRangePickerField } from "@fas/cpa-cabinet-ui/lib/Table/SearchComponent";
import { withHookPropsState } from "@fas/cpa-cabinet-ui";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import { accordionStyles } from "../../styles";
import { mainReportColumnsMap as columnsMap } from "../Reports";
import Checkbox, { type Props as CheckboxProps } from "./Checkbox";
import { maxDate, minDate, presets } from "../Reports/presets";
import DateRangeFooter from "../DateRangeFooter";
import DateInputComponent from "./DateInputComponent";
import SelectPopup, { SelectPopupField } from "../SelectPopup/SelectPopup";
import type { Props as PropsSelect } from "../SelectPopup/SelectPopup";
import { useDictionaryValueList } from "../../hooks/useDictionaryValueList";
import FieldMultiselect, { type Props as PropsTextFieldMultiselect } from "../TextFieldMultiselect";
import { useMemoryOptions, type UseMemoryOptionsHook } from "../../hooks/useMemoryOptions";
import { useConfigureAccordion } from "../../hooks/useConfigureAccordion";

type Props = {
  filters: Filters,
  fields: string[],
  onChangeFilters: (Filters) => mixed,
  onChangeSorting: (Sorting) => mixed,
  onChangeFields: (string[]) => mixed,
  onClose?: () => mixed,
  onApply?: () => mixed,
  onUpdatePosition?: () => mixed,
  open?: boolean,
};

const StyledAccordion: StatelessFunctionalComponent<*> = withStyles(accordionStyles)(
  withHookPropsState(Accordion, useConfigureAccordion)
);
const CountrySelect: StatelessFunctionalComponent<
  ComponentProps<PropsSelect, Dictionaries, UseDictionaryType>
  > = withHookPropsState(SelectPopup, useDictionaryValueList);

const TextFieldMultiselect: StatelessFunctionalComponent<
  ComponentProps<PropsTextFieldMultiselect, string, UseMemoryOptionsHook>
  > = withHookPropsState(FieldMultiselect, useMemoryOptions);

const groupByList: string[] = [
  "date",
  "country",
  "subId",
];

const ConfigureMainReport: StatelessFunctionalComponent<Props> = ({
  filters,
  fields,
  onChangeFields,
  onChangeFilters,
  onClose,
  onApply,
  open,
  onUpdatePosition,
}) => {
  const theme: * = useTheme();
  const [pendingFilters, setPendingFilters]: * = useState(filters);
  const [pendingFields, setPendingFields]: * = useState(fields);
  function isDisableChecked(key) {
    return pendingFields.length <= 2 && pendingFields.includes(key);
  }

  const getCheckboxProps: (string) => CheckboxProps = (key) => ({
    checked: pendingFields.includes(key),
    label: columnsMap[key].label,
    disabled: (pendingFilters.groupBy !== key && groupByList.includes(key)) || isDisableChecked(key),
    onChange: (checked: boolean) => {
      if (checked) {
        setPendingFields([...pendingFields, key]);
      }
      else {
        setPendingFields(pendingFields.filter((field: string): boolean => field !== key));
      }
    },
  });
  const columnsKeys: string[] = Object.keys(columnsMap);

  const handleApply: * = () => {
    onChangeFilters(pendingFilters);
    onChangeFields(pendingFields);
    onApply && onApply();
    onClose && onClose();
  };
  useEffect((): * => () => {
    if (!open) { // save fields & filters on close modal
      onChangeFilters(pendingFilters);
      onChangeFields(pendingFields);
    }
  }, [open, pendingFilters, pendingFields]);

  const Select: StatelessFunctionalComponent<PropsSelect> = SelectPopupField;

  const groupByOptions = groupByList.reduce((acc, option: string) => ([
    ...acc,
    {
      value: option,
      title: columnsMap[option].label,
    },
  ]), []);

  return (
    <>
      <Box sx={(): * => ({
        zIndex: 1,
        position: "sticky",
        top: theme.spacing(-1),
        margin: "-10px -10px 0",
        padding: "2px 0 2px 10px",
        bgcolor: "background.light",
        display: { xs: "flex", sm: "none" },
        justifyContent: "space-between",
        alignItems: "center",
      })}
      >
        <Typography variant="h5">Filter</Typography>
        <IconButton data-testid="close-btn" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ margin: { xs: "0 -10px", sm: 0 } }}>
        <StyledAccordion
          hookProps={{
            name: "dateRangeMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Date range">
            <Typography>Date range</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DateRangePickerField
              applyOnClose
              applyOnSelect
              DateInputComponent={DateInputComponent}
              presets={presets}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(dateFilters: *): * => setPendingFilters({
                ...pendingFilters,
                ...dateFilters,
              })}
              value={pendingFilters.date}
              filters={pendingFilters}
              filterKey="date"
              data-testid="date"
              readOnly={false}
              format="YYYY-MM-DD"
              mask="9999-99-99 - 9999-99-99"
              FooterComponent={DateRangeFooter}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "groupByMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Group by">
            <Typography>Group by</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              disableSearch
              isLoading={false}
              value={pendingFilters.groupBy}
              name="group"
              data-testid="group"
              options={groupByOptions}
              placeholder="Select Group By"
              defaultValue=""
              disableClearable
              onChange={(value: *): * => {
                if (!value) return;
                setPendingFilters({
                  ...pendingFilters,
                  groupBy: value,
                });
                // $FlowFixMe
                setPendingFields([
                  ...pendingFields.filter((field: string): boolean => (
                    field !== value && !groupByList.includes(field))),
                  value,
                ]);
              }}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "showColumnsMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Show columns">
            <Typography>Show columns</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs>
                {columnsKeys
                  .slice(0, Math.ceil(columnsKeys.length / 2))
                  .map((key: string): Element<*> => (<Checkbox key={key} {...getCheckboxProps(key)} />))}
              </Grid>
              <Grid item xs>
                {columnsKeys
                  .slice(Math.ceil(columnsKeys.length / 2), columnsKeys.length)
                  .map((key: string): Element<*> => (<Checkbox key={key} {...getCheckboxProps(key)} />))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "subIdMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Subid">
            <Typography>Subid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="subIdMain"
              name="subId"
              placeholder="Input subId"
              placeholderInput="Input subId"
              value={pendingFilters.subId}
              onChange={(e: *): mixed => setPendingFilters({
                ...pendingFilters,
                subId: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "countryMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Country">
            <Typography>Country</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CountrySelect
              value={pendingFilters.country}
              name="country"
              placeholder="Select country(s)"
              onChange={(country: *): * => setPendingFilters({ ...pendingFilters, country })}
              hookProps="country"
              defaultValue={[]}
              isSplitListBoxColumn
            />
          </AccordionDetails>
        </StyledAccordion>
      </Box>
      <Box sx={(): * => ({
        zIndex: 1,
        position: "sticky",
        bottom: theme.spacing(-1),
        marginTop: { xs: theme.spacing(4), sm: theme.spacing(2) },
        bgcolor: "background.default",
      })}
      >
        <Button disabled={pendingFields.length < 2} data-testid="apply-btn" variant="gradient" fullWidth onClick={handleApply}>
          Apply filters
        </Button>
      </Box>
    </>
  );
};

export default ConfigureMainReport;
